import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MallParkingSection = () => {
  const data = useStaticQuery(graphql`
    query {
      mallParkingCar: file(
        relativePath: { eq: "products/mall-parking-car.png" }
      ) {
        publicURL
      }
      mallParking: file(relativePath: { eq: "products/mall-parking.png" }) {
        publicURL
      }
      mallParkingDesktop: file(relativePath: { eq: "products/mall-parking-desktop.png" }) {
        publicURL
      }
      graphZulPlus: file(relativePath: { eq: "products/graph-zul-plus.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-mall-parking section__right-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Estacionamento <br className="is-hidden-mobile" />
          de shopping
        </h2>
        <p className="subtitle">
          Pague o ticket de shopping direto pelo app e não perca tempo nas filas
          do guichê.
        </p>

        <div className="images">
          <img
            className="mall-parking-car"
            src={data.mallParkingCar.publicURL}
            alt=""
          />
          <img
            className="mall-parking is-hidden-desktop"
            src={data.mallParking.publicURL}
            alt=""
            />
          <img
            className="mall-parking is-hidden-mobile"
            src={data.mallParkingDesktop.publicURL}
            alt=""
            />
          <img
            className="mall-parking-zul-plus"
            src={data.graphZulPlus.publicURL}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default MallParkingSection
